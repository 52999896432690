import { ContextMenu } from '@blueprintjs/core';
import {
  Location,
  Seed,
  Zone as ZoneType,
} from '@humancollective/seedz-shared';
import { navigate } from 'gatsby';
import geolib from 'geolib';
import * as React from 'react';
import { GoogleMap, withGoogleMap, withScriptjs } from 'react-google-maps';
import styled from 'styled-components';
import SearchBox from 'react-google-maps/lib/components/places/SearchBox';

import bluewaterMapStyle from '../../config/bluewaterMapStyle';
import {
  MapContext,
  SeedzContext,
  UIContext,
  UserCampaignsContext,
  UserLocationsContext,
  ZonesContext,
} from '../../contexts';

import Loading from '../Loading';
import Marker from './Marker';
import Menu from './Menu';
import Zone from './Zone';

import { Firebase, isPositionInZone } from '../../utilities';
import AddSeedModal from '../Modal/AddSeed';
import EditSeedModal from '../Modal/EditSeed';

const FullSizeDiv = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
`;

const SearchBoxInput = styled.input`
  margin-top: 10px;
  background: white;
  padding: 12px;
  box-shadow: rgba(0, 0, 0, 0.3) 0px 1px 4px -1px;
  border-radius: 3px;
  border: none;
  font-size: 14px;
  width: 240px;
`;

export interface MapMarkerData {
  type: 'location' | 'seed';
  data: Location | Seed;
}

interface MarkerState {
  filtered: MapMarkerData[];
  all: MapMarkerData[];
}

interface MapProps {
  className: string;
  searchParams: {
    type?: string;
    id?: string;
  };
  defaultCenter: {
    lat: number;
    lng: number;
  };
}

const Map: React.FunctionComponent<MapProps> = ({
  searchParams,
  defaultCenter,
  ...rest
}) => {
  const mapRef = React.useRef<GoogleMap>(null);
  const searchRef = React.useRef<SearchBox>(null);

  const seedz = React.useContext(SeedzContext);
  const userCampaigns = React.useContext(UserCampaignsContext);
  const userLocations = React.useContext(UserLocationsContext);
  const ui = React.useContext(UIContext);
  const zones = React.useContext(ZonesContext);
  const mapCtx = React.useContext(MapContext);

  const [markers, setMarkers] = React.useState<MarkerState>({
    filtered: [],
    all: [],
  });

  // If the Google Map is not given a default center, it won't load.
  // This simply tells us if it's not set, since the map doesn't tell us.
  // Delete this if you feel its useless.
  React.useEffect(() => {
    if (mapRef.current && !mapRef.current.props.defaultCenter) {
      console.error('No defaultCenter prop set. Map will not load.');
    }

    mapCtx.setDefaultCenter({
      latitude: defaultCenter.lat,
      longitude: defaultCenter.lng,
    });

    if (mapRef.current) {
      mapRef.current.panTo({ ...defaultCenter });
    }
  }, [defaultCenter]);

  // If we have a specified type & id in the url, set the selected item
  // we use the userCampaigns & userLocations as deps, because on the initial load
  // the campaigns may not yet be available
  React.useEffect(() => {
    const { type, id } = searchParams;
    if (type === 'campaign') {
      const campaign = userCampaigns.find(c => c.id === id);
      if (campaign) {
        mapCtx.setSelectedItem({ type: 'campaign', data: campaign });
      }
    } else if (type === 'location' && userLocations !== null) {
      const locationMatch = userLocations.find(l => l.id === id);
      if (locationMatch) {
        mapCtx.setSelectedItem({ type: 'location', data: locationMatch });
      }
    } else if (type === 'zone') {
      const matchingZone = zones.find(zone => zone.id === id);
      if (matchingZone) {
        mapCtx.setSelectedItem({ type: 'zone', data: matchingZone });
      }
    } else {
      mapCtx.removeSelectedItem();
    }
  }, [searchParams, userCampaigns, userLocations, zones]);

  // If we get any new locations, seedz, campaigns, or selected item
  React.useEffect(() => {
    // All the Seedz markers
    const seedzMarkers: MapMarkerData[] = seedz
      .filter(seed =>
        userCampaigns.find(tempCampaign => tempCampaign.id === seed.campaign)
      )
      .map(seed => ({
        type: 'seed' as 'seed', // Typescript for some reason requires the `as 'seed'`
        data: seed,
      }));

    // All the location markers
    let locationMarkers: MapMarkerData[] =
      userLocations !== null
        ? userLocations.map(l => ({
          type: 'location' as 'location',
          data: l,
        }))
        : [];

    // If we have a selected item on the map, and it's a location
    // Add it to the location markers. Usually the selected item
    // Is an item we're creating, and we want to show it on the map
    // Without adding it to firebase first
    if (mapCtx.selectedItem && mapCtx.selectedItem.type === 'location') {
      // Remove the location marker if it's already existing
      locationMarkers = locationMarkers.filter(
        l => l.data.id !== mapCtx.selectedItem!.data.id
      );
      // Add the selected item location
      locationMarkers.push({
        type: 'location',
        data: mapCtx.selectedItem.data as Location,
      });
    }

    setMarkers(prevState => ({
      ...prevState,
      all: [...locationMarkers, ...seedzMarkers],
    }));
  }, [seedz, userLocations, userCampaigns, mapCtx.selectedItem]);

  // If the markers change
  React.useEffect(() => {
    const { selectedItem } = mapCtx;
    // if the selected item is a location, pan to it
    if (selectedItem && selectedItem.type === 'location') {
      const locationMarkers = markers.all.filter(
        marker => marker.data.id === selectedItem.data.id
      );
      const firstMarker = locationMarkers[0];
      if (firstMarker && firstMarker.data.position && mapRef.current) {
        mapRef.current.panTo({
          lat: firstMarker.data.position.latitude,
          lng: firstMarker.data.position.longitude,
        });
      }
      setMarkers(prevState => ({ ...prevState, filtered: locationMarkers }));
    } else if (selectedItem && selectedItem.type === 'campaign') {
      // if the selected item is a campaign, get all the seed markers for that campaign
      // And pan to it & fit all the seedz in the bounds
      const campaignMarkers = markers.all.filter(
        marker => marker.data.campaign === selectedItem.data.id
      );
      const coords = campaignMarkers.map(marker => marker.data.position);
      const bounds = geolib.getBounds(coords);
      if (bounds && mapRef.current) {
        mapRef.current.fitBounds({
          north: bounds.maxLat,
          east: bounds.maxLng,
          south: bounds.minLat,
          west: bounds.minLng,
        });
      }
      setMarkers(prevState => ({ ...prevState, filtered: campaignMarkers }));
    } else if (selectedItem && selectedItem.type === 'zone') {
      const selectedZone = selectedItem.data as ZoneType;
      // If they have more than 3 selected points for the zone bounds, then zoom in
      // 3 selected points makes a complete zone.
      if (selectedZone.bounds.length >= 3) {
        const bounds = geolib.getBounds(selectedZone.bounds);
        if (bounds && mapRef.current) {
          mapRef.current.fitBounds({
            north: bounds.maxLat,
            east: bounds.maxLng,
            south: bounds.minLat,
            west: bounds.minLng,
          });
        }
      }

      // Gets the markers that are inside the Zone
      const markersInZone = markers.all.filter(marker =>
        geolib.isPointInside({ ...marker.data.position }, [
          ...(selectedItem.data as ZoneType).bounds,
        ])
      );
      setMarkers(prevState => ({
        ...prevState,
        filtered: markersInZone,
      }));
    } else {
      setMarkers(prevState => ({
        ...prevState,
        filtered: markers.all,
      }));
    }
  }, [markers.all]);

  const addSeed = React.useCallback((seed: Seed) => {
    ui.modal.show({
      heading: 'Add Seed',
      body: <AddSeedModal seed={seed} />,
    });
  }, []);

  return (
    <GoogleMap
      {...rest}
      ref={mapRef}
      defaultCenter={defaultCenter}
      defaultZoom={18}
      options={{
        styles: bluewaterMapStyle,
        streetViewControl: false,
        scaleControl: false,
        mapTypeControl: true,
        panControl: false,
        zoomControl: false,
        rotateControl: false,
        fullscreenControl: false,
      }}
      onRightClick={({ latLng, pixel }) => {
        const position = { latitude: latLng.lat(), longitude: latLng.lng() };
        ContextMenu.show(
          <Menu
            position={position}
            selected={mapCtx.selectedItem}
            setSelectedPosition={mapCtx.setSelectedPosition}
            addSeed={addSeed}
            isPositionInZone={Boolean(isPositionInZone({ zones, position }))}
          />,
          {
            left: pixel.x + 360, // account for sidebar
            top: pixel.y,
          }
        );
      }}
    >
      <SearchBox
        ref={searchRef}
        controlPosition={google.maps.ControlPosition.TOP_CENTER}
        onPlacesChanged={() => {
          if (!searchRef.current || !mapRef.current) return;
          const places = searchRef.current.getPlaces();
          const selectedPlace = places[0];
          const { geometry } = selectedPlace;
          if (!geometry) return;
          const { location } = geometry;
          const lat = location.lat();
          const lng = location.lng();
          mapRef.current.panTo({ lat, lng });
        }}
      >
        <SearchBoxInput type="text" placeholder="Search for an Address" />
      </SearchBox>
      {/* If the selected item is a zone, show that zone. If not, show all the zones */}
      {mapCtx.selectedItem && mapCtx.selectedItem.type === 'zone' ? (
        <Zone
          {...mapCtx.selectedItem.data as ZoneType}
          showBoundPoints={true}
        />
      ) : (
        zones.map(zone => (
          <Zone
            key={zone.id}
            {...zone}
            allowInteractions={!mapCtx.selectedItem}
          />
        ))
      )}

      {/* Markers for locations/seedz */}
      {markers.filtered.map((filteredMarker: MapMarkerData) => {
        const isMarkerSeed = filteredMarker.type.valueOf() === 'seed';
        return (
          <Marker
            key={filteredMarker.data.id}
            onClick={() => {
              const navUrl = isMarkerSeed
                ? `type=campaign&id=${(filteredMarker.data as Seed).campaign}`
                : `type=location&id=${filteredMarker.data.id}`;

              // If we already have the marker campaign selected, and they click on a seed marker
              // Show the modal for that seed
              if (
                isMarkerSeed &&
                searchParams.id === (filteredMarker.data as Seed).campaign &&
                searchParams.type === 'campaign'
              ) {
                ui.modal.show({
                  heading: 'Edit Seed',
                  body: <EditSeedModal seed={filteredMarker.data as Seed} />,
                });
              } else {
                navigate(`/map?${navUrl}`);
              }
            }}
            imageUrl={filteredMarker.data.markerImage}
            position={{
              lat: filteredMarker.data.position
                ? filteredMarker.data.position.latitude
                : 0,
              lng: filteredMarker.data.position
                ? filteredMarker.data.position.longitude
                : 0,
            }}
            onRightClick={
              isMarkerSeed
                ? async () => Firebase.removeSeed(filteredMarker.data.id)
                : undefined
            }
          />
        );
      })}
    </GoogleMap>
  );
};

const ConnectedMap = withScriptjs(withGoogleMap(Map));

const MapBase: React.FunctionComponent<MapProps> = ({ children, ...rest }) => (
  <ConnectedMap
    {...rest}
    googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${
      process.env.GATSBY_MAP_API_KEY
    }&v=3.exp&libraries=geometry,drawing,places`}
    loadingElement={<Loading />}
    containerElement={<FullSizeDiv />}
    mapElement={<FullSizeDiv />}
  />
);

export default MapBase;
