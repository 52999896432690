import * as React from 'react';
import { Zone as ZoneType } from '@humancollective/seedz-shared';
import { Marker, MarkerProps, Polygon } from 'react-google-maps';
import { navigate } from 'gatsby';

import { Colors } from '../../config';

const determineZoneColor = ({
  live,
  options,
}: {
  live: boolean;
  options: {
    creditMultiplier?: number;
    enabledBusinesses?: string[];
    disableMarkers?: boolean;
  };
}): string => {
  if (!live) {
    return Colors.SECONDARY;
  }
  if (options.disableMarkers) {
    return Colors.RED;
  }
  return Colors.PRIMARY_LIGHT;
};

const BoundPoint: React.FunctionComponent<
  MarkerProps & { innerLabel: string }
> = ({ position, innerLabel }) => (
  <Marker
    position={position}
    label={{ text: innerLabel, color: '#FFFFFF' }}
    icon={{
      labelOrigin: new google.maps.Point(17, 11),
      url: 'https://maps.google.com/mapfiles/ms/icons/red.png',
      scaledSize: new google.maps.Size(35, 35),
    }}
  />
);

interface ZoneProps {
  showBoundPoints?: boolean;
  allowInteractions?: boolean;
}
const Zone: React.FunctionComponent<ZoneType & ZoneProps> = ({
  bounds = [],
  id,
  live,
  options,
  showBoundPoints = false,
  allowInteractions = true,
}) => {
  const [isHovering, setIsHovering] = React.useState<boolean>(false);
  return (
    <>
      {/* Shows the bounds points when editing */}
      {showBoundPoints &&
        bounds.map((bound, index) => (
          <BoundPoint
            key={`${bound.latitude},${bound.longitude}`}
            position={{ lat: bound.latitude, lng: bound.longitude }}
            innerLabel={`${index + 1}`}
          />
        ))}
      <Polygon
        paths={bounds.map(coordinates => ({
          lat: coordinates.latitude,
          lng: coordinates.longitude,
        }))}
        options={{
          strokeOpacity: 0.0,
          fillColor: determineZoneColor({ live, options }),
          fillOpacity: isHovering ? 0.65 : 0.45,
          clickable: allowInteractions,
        }}
        onMouseOver={() => setIsHovering(true)}
        onMouseOut={() => setIsHovering(false)}
        onClick={() => {
          navigate(`/map?type=zone&id=${id}`);
        }}
      />
    </>
  );
};

export default Zone;
