import React from 'react';
import { Menu } from '@blueprintjs/core';
import { Seed, createId } from '@humancollective/seedz-shared';

import { SelectedMapItem } from '../../contexts';

interface SeedzMapMenuProps {
  selected?: SelectedMapItem;
  position: { latitude: number; longitude: number };
  setSelectedPosition: ({
    latitude,
    longitude,
  }: {
    latitude: number;
    longitude: number;
  }) => void;
  addSeed: (seed: Seed) => void;
  isPositionInZone?: boolean; // Pass in boolean if the given position is in a zone
}

const MapMenu: React.FunctionComponent<SeedzMapMenuProps> = ({
  position,
  selected,
  setSelectedPosition,
  addSeed,
  isPositionInZone,
}) => {
  return (
    <Menu>
      {selected && selected.type === 'location' && (
        <Menu.Item
          icon="send-to-map"
          text={`Move Location ${isPositionInZone ? ' To Zone' : 'Here'}`}
          onClick={() => {
            setSelectedPosition({ ...position });
          }}
        />
      )}
      {selected && selected.type === 'zone' && (
        <Menu.Item
          icon="send-to-map"
          text="Set zone point here"
          onClick={() => {
            setSelectedPosition({ ...position });
          }}
        />
      )}
      {selected && selected.type === 'campaign' && (
        <Menu.Item
          icon="add"
          text={`Add Seed ${isPositionInZone ? ' To Zone' : 'Here'}`}
          onClick={() => {
            if (!selected.data) return;
            // We're missing some fields for the seed, but the backend will fill
            // In the relevant information
            // @ts-ignore
            const seedToAdd: Seed = {
              position,
              id: createId(),
              campaign: selected.data.id,
              markerImage: '/utils/seedz-icon.png',
              owner: '',
            };
            addSeed(seedToAdd);
          }}
        />
      )}
    </Menu>
  );
};

export default MapMenu;
