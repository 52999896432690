import { Seed } from '@humancollective/seedz-shared';
import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { API, DateUtility } from '../../utilities';
import { UIContext } from '../../contexts';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Actions = styled.div`
  margin-top: 15px;
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
`;

const SeedMapImage = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  > img {
    height: 100px;
    width: 100px;
    border-radius: 50px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`;

const Row = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;

  > img {
    height: 75px;
    width: 75px;
    border-radius: 37.5px;
  }
`;

const InnerLabel = styled.p`
  font-size: 12px;
  margin: 0 5px;
  color: gray;
`;

interface EditSeed {
  seed: Seed;
}

const createStaticMapURL = (seed: Seed) => {
  // The base URL
  let defaultURL =
    'https://maps.googleapis.com/maps/api/staticmap?zoom=16&maptype=roadmap&size=200x200&scale=4';
  // Adding the centre coordinate
  defaultURL += `&center=${seed.position.latitude},${seed.position.longitude}`;
  // The marker
  defaultURL += `&markers=color:red|label:Seed|${seed.position.latitude},${
    seed.position.longitude
  }`;
  // Adding the API key
  const urlWithAPIkey = `${defaultURL}&key=${process.env.GATSBY_MAP_API_KEY}`;
  // Encode the URL
  return encodeURI(urlWithAPIkey);
};

const EditSeed: React.FunctionComponent<EditSeed> = ({ seed }) => {
  const ui = React.useContext(UIContext);
  const [newExpiryDate, setNewExpiryDate] = React.useState(
    seed.expiryDate as DateUtility.Timestamp
  );

  const [creditCost, setCreditCost] = React.useState<number>(0);
  const [isRequesting, setIsRequesting] = React.useState<boolean>(false);

  const updateExpiryDate = async (amount: number) => {
    const updatedExpiryDate = DateUtility.addTimeperiodToTimestamp(
      newExpiryDate,
      {
        days: amount,
      }
    );
    setNewExpiryDate(updatedExpiryDate);

    setIsRequesting(true);
    const creditCostRequest = await API.post({
      url: '/api/calculateCreditCost',
      data: {
        item: { ...seed, expiryDate: updatedExpiryDate },
        itemType: 'seed',
      },
    });

    if (creditCostRequest.status === 'success') {
      setCreditCost(creditCostRequest.values.credits);
    } else {
      // if the request fails, fallback to the previous expiry date
      setNewExpiryDate(newExpiryDate);
    }
    setIsRequesting(false);
  };

  return (
    <Container>
      <Row>
        <SeedMapImage>
          <img src={createStaticMapURL(seed)} />
        </SeedMapImage>
        <Content>
          <h3>Expiry Date</h3>
          <Row>
            <Button
              icon={IconNames.MINUS}
              onClick={async () => updateExpiryDate(-1)}
              disabled={DateUtility.areTimestampsEqual(
                seed.expiryDate,
                newExpiryDate
              )}
            />
            <InnerLabel>
              {DateUtility.formatBillingDate(newExpiryDate)}
            </InnerLabel>
            <Button
              icon={IconNames.PLUS}
              onClick={async () => updateExpiryDate(1)}
            />
          </Row>
        </Content>
      </Row>
      <Actions>
        <Button
          text={`Save (${creditCost} credits)`}
          intent="success"
          disabled={creditCost === 0 || isRequesting}
          onClick={async () => {
            await API.post({
              url: '/api/addSeed',
              data: { seed: { ...seed, expiryDate: newExpiryDate } },
              authenticate: true,
            });
            ui.modal.dismiss();
          }}
        />
      </Actions>
    </Container>
  );
};

export default EditSeed;
