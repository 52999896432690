import React from 'react';
import styled from 'styled-components';

import PanelList from './Panel';
import EditCampaignForm from '../Forms/EditCampaignForm';
import EditLocationForm from '../Forms/EditLocationForm';
import ZoneForm from '../Forms/Zone';

const StyledSidebar = styled.div`
  flex: 1;
  max-width: 360px;
  height: 100%;
  max-height: 100vh;
  overflow: auto;
  border-right: 1px solid #d4d6d9;
`;

interface SidebarProps {
  searchParams: {
    type?: string;
    id?: string;
  };
}

const Sidebar: React.FunctionComponent<SidebarProps> = ({ searchParams }) => (
  <StyledSidebar>
    {searchParams.type === 'campaign' && (
      <EditCampaignForm campaignId={searchParams.id || 'new'} />
    )}
    {searchParams.type === 'location' && (
      <EditLocationForm locationId={searchParams.id || 'new'} />
    )}
    {searchParams.type === 'zone' && (
      <ZoneForm zoneId={searchParams.id || 'new'} />
    )}
    {!searchParams.type && <PanelList />}
  </StyledSidebar>
);

export default Sidebar;
