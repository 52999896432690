import React from 'react';
import { IoIosNotifications } from 'react-icons/io';
import styled from 'styled-components';
import { Campaign, Location } from '@humancollective/seedz-shared';
import { Tag } from '@blueprintjs/core';

import categories from '../../../config/categories';
import { Imgix } from '../../../utilities';

const StyledMapListRow = styled.div`
  padding: 12px;
  border: none;
  overflow: hidden;
  background: white;
  flex-grow: 1;
  flex-basis: 360px;
  cursor: pointer;
  border-bottom: 1px solid #f5f5f5;

  .map-list__row {
    &__information-container {
      display: flex;
      align-items: center;
    }

    &__information {
      flex-grow: 1;
      width: 180px;
    }

    &__image {
      height: 45px;
      width: 45px;
      background-size: contain;
      background-position: center;
      background-repeat: no-repeat;
      background-color: white;
      border-radius: 22.5px;
      margin-right: 24px;
      margin-left: 12px;
    }

    &__type {
      text-transform: uppercase;
      font-size: 11px;
      font-weight: 600;
      color: #6db54c;
    }

    &__title {
      margin-bottom: 0;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__subtitle {
      font-weight: 300;
    }

    &__status {
      font-size: 12px;
      padding: 3px 6px;
      display: inline-block;
      background: #efefef;
      border-radius: 6px;
    }
  }
`;

interface PanelRowProps {
  type: 'campaign' | 'location';
  data: Campaign | Location;
  onClick: () => void;
}

const PanelRow: React.FunctionComponent<PanelRowProps> = ({
  type,
  data,
  onClick,
}) => {
  const category = categories[data.type];

  return (
    <StyledMapListRow onClick={onClick}>
      <div className="map-list__row__information-container">
        {data.markerImage && (
          <img
            className="map-list__row__image"
            src={Imgix.createUrl(data.markerImage, {
              background: 'ffffff',
              height: 45,
              width: 45,
              auto: 'COMPRESS',
              mask: 'CORNERS',
              cornerRadius: 22.5,
              dpr: '3',
              fit: 'CLAMP',
            })}
          />
        )}

        <div className="map-list__row__information">
          <div className="map-list__row__type bp3-ui-text">
            <IoIosNotifications />
            {type === 'campaign' ? 'Campaign' : 'Location'}
          </div>
          <h5 className="map-list__row__title bp3-heading">{data.name}</h5>
          {category && (
            <div className="map-list__row__subtitle bp3-ui-text">
              {category.label}
            </div>
          )}
          <Tag intent={data.live ? 'success' : 'none'} minimal={true}>
            {data.live ? 'Live' : 'Not Live'}
          </Tag>
        </div>
      </div>
    </StyledMapListRow>
  );
};

export default PanelRow;
