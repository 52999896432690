import React from 'react';
import styled from 'styled-components';
import { Button, Callout, Menu, Popover, Position } from '@blueprintjs/core';
import { navigate } from 'gatsby';
import { Campaign, Location } from '@humancollective/seedz-shared';

import {
  ProfileContext,
  UserCampaignsContext,
  UserLocationsContext,
  IsUserAdminContext,
} from '../../../contexts';
import PanelRow from './Row';

const StyledMapList = styled.div`
  min-width: 360px;
  display: flex;
  flex-direction: column;
  height: 100vh;

  .pane-list__controls {
    display: flex;
    padding: 0 12px 12px;
    justify-content: center;
    background-color: #f4f8fa;

    > .bp3-select,
    > .bp3-input-group {
      flex: 1;
      margin: 6px;
    }

    > .bp3-popover-wrapper {
      margin: 6px;
      flex: 0;
    }

    &:first-of-type {
      padding-top: 12px;
    }
  }
`;

interface RowData {
  type: 'campaign' | 'location';
  data: Campaign | Location;
}

const MapList: React.FunctionComponent = () => {
  const userCampaigns = React.useContext(UserCampaignsContext);
  const userLocations = React.useContext(UserLocationsContext);
  const profile = React.useContext(ProfileContext);
  const isAdmin = React.useContext(IsUserAdminContext);

  const [queryText, setQueryText] = React.useState('');
  const [queryStatus, setQueryStatus] = React.useState('all');
  const [queryType, setQueryType] = React.useState('all');
  const [userHasBusiness, setUserHasBusiness] = React.useState<boolean>(false);

  const [rows, setRows] = React.useState([] as RowData[]);

  // Check if the user belongs to a business or not
  React.useEffect(() => {
    const doesHaveBusiness = Boolean(profile && Boolean(profile.business));
    setUserHasBusiness(doesHaveBusiness);
  }, [profile]);

  React.useEffect(() => {
    const locationRows: RowData[] = userLocations
      ? userLocations.map(l => ({
          type: 'location' as 'location', // hack, for some reason typescript does't accept 'location'
          data: l,
        }))
      : [];
    const campaignRows: RowData[] = userCampaigns.map(campaign => ({
      type: 'campaign' as 'campaign',
      data: campaign,
    }));
    const sortedRows: RowData[] = [...locationRows, ...campaignRows].sort(
      (a, b) => (a.data.name > b.data.name ? 1 : -1)
    );
    setRows(sortedRows);
  }, [userLocations, userCampaigns]);

  return (
    <StyledMapList>
      <div className="pane-list__controls">
        {userHasBusiness ? (
          <Popover
            content={
              <Menu>
                <Menu.Item
                  text="Location"
                  icon="pin"
                  onClick={() => {
                    navigate(`/map?type=location&id=${'new'}`);
                  }}
                />
                <Menu.Item
                  text="Campaign"
                  icon="calendar"
                  onClick={() => {
                    navigate(`/map?type=campaign&id=${'new'}`);
                  }}
                />
                {isAdmin && (
                  <Menu.Item
                    text="Zone"
                    icon="search-around"
                    onClick={() => {
                      navigate('/map?type=zone&id=new');
                    }}
                  />
                )}
              </Menu>
            }
            position={Position.BOTTOM}
          >
            <Button icon="add" rightIcon="caret-down" text="Add" />
          </Popover>
        ) : (
          <Popover
            interactionKind="hover"
            content={<Callout>You do not belong to a business.</Callout>}
            position={Position.BOTTOM}
            minimal={true}
          >
            <Button
              icon="add"
              rightIcon="caret-down"
              text="Add"
              disabled={true}
            />
          </Popover>
        )}

        <div className="bp3-input-group">
          <span className="bp3-icon bp3-icon-search" />
          <input
            className="bp3-input"
            type="search"
            value={queryText}
            onChange={e => setQueryText(e.target.value.toLowerCase())}
            placeholder="Search by name..."
          />
        </div>
      </div>
      <div className="pane-list__controls">
        <div className="bp3-select">
          <select onChange={e => setQueryType(e.target.value)}>
            <option value="all">All Types</option>
            <option value="campaign">Campaigns</option>
            <option value="location">Locations</option>
          </select>
        </div>

        <div className="bp3-select">
          <select onChange={e => setQueryStatus(e.target.value)}>
            <option value="all">All Statuses</option>
            <option value="live">Live</option>
            <option value="not-live">Not Live</option>
          </select>
        </div>
      </div>
      <div className="locations-page__locations">
        {rows
          .filter(({ data, type }) => {
            if (!data) return false;

            // filter search query
            if (
              queryText &&
              (!data.name || !data.name.toLowerCase().includes(queryText))
            )
              return false;

            // filter type selection
            if (queryType !== 'all' && type !== queryType) return false;

            // filter status selection
            if (queryStatus !== 'all') {
              if (queryStatus === 'live' && !data.live) return false;
              if (queryStatus === 'not-live' && data.live) return false;
            }

            return true;
          })
          .map((dataItem: RowData) => {
            return (
              <PanelRow
                key={dataItem.data.id}
                type={dataItem.type}
                data={dataItem.data}
                onClick={() =>
                  navigate(`/map?type=${dataItem.type}&id=${dataItem.data.id}`)
                }
              />
            );
          })}
      </div>
    </StyledMapList>
  );
};

export default MapList;
