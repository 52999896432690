import * as React from 'react';
import { AuthenticatedContent as AuthContent } from '@humancollective/human-dashboard';
import { ProfileContext } from '../../contexts';
import { navigate } from 'gatsby';
import { Routes } from '../../config';
import Loading from '../Loading';

const AuthenticatedContent: React.FunctionComponent = ({ children }) => {
  const profile = React.useContext(ProfileContext);
  const [isBusiness, setIsBusiness] = React.useState(null as boolean | null);

  React.useEffect(() => {
    if (profile === null) return; // check if it's still loading
    if (profile && !profile.business) navigate(Routes.REGISTER);
    else setIsBusiness(true);
  }, [profile]);

  return <AuthContent>{isBusiness ? children : <Loading />}</AuthContent>;
};

export default AuthenticatedContent;
