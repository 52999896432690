import * as React from 'react';
import styled from 'styled-components';
import { Business } from '@humancollective/seedz-shared';
import { Imgix } from '../../../utilities';
import { Colors } from '../../../config';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

const ListContainer = styled.ul`
  width: 100%;
  margin: 0;
  margin-top: 8px;
  padding: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BusinessList: React.FunctionComponent = ({ children }) => (
  <ListContainer>{children}</ListContainer>
);

const ListItem = styled.li`
  margin: 0;
  padding: 8px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background-color: white;
  border-bottom: 1px solid lightgray;

  :last-child {
    border-bottom: none;
  }
  &:hover {
    background-color: ${Colors.BP_BACKGROUND};
  }

  > img {
    height: 40px;
    width: 40px;
    border-radius: 20px;
    margin-right: 20px;
  }

  > p {
    margin: 0;
  }

  > button {
    margin-left: auto;
  }
`;

const BusinessListItem: React.FunctionComponent<
  Business & { onClick: () => void; isSearching?: boolean }
> = ({ onClick, name, id, logo, isSearching = false }) => (
  <ListItem>
    <img
      src={Imgix.createUrl(logo, {
        height: 40,
        width: 40,
        cornerRadius: 20,
        mask: 'CORNERS',
      })}
    />
    <p>{name}</p>
    <Button
      icon={isSearching ? IconNames.ADD : IconNames.CROSS}
      minimal={true}
      onClick={onClick}
    />
  </ListItem>
);

export { BusinessList, BusinessListItem };
