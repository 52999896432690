import ACCOMMODATIONS from './icon-accommodations.png'
import ACTIVITIES from './icon-activities.png'
import AUTOMOTIVE from './icon-automotive.png'
import ENTERTAINMENT_AND_MUSIC from './icon-entertainment-and-music.png'
import FOOD_AND_DRINK from './icon-food-and-drink.png'
import HISTORICAL from './icon-historical.png'
import HOME_AND_GARDEN from './icon-home-and-garden.png'
import NON_PROFIT from './icon-non-profit.png'
import PETS from './icon-pets.png'
import RELIGIOUS from './icon-religious.png'
import RETAIL_AND_SHOPPING from './icon-retail-and-shopping.png'
import SERVICES from './icon-services.png'
import SPORTS from './icon-sports.png'
import THEATRE from './icon-theatre.png'
import TRAVEL from './icon-travel.png'
import WELLNESS from './icon-wellness.png'

export default {
  ACCOMMODATIONS,
  ACTIVITIES,
  AUTOMOTIVE,
  ENTERTAINMENT_AND_MUSIC,
  FOOD_AND_DRINK,
  HISTORICAL,
  HOME_AND_GARDEN,
  NON_PROFIT,
  PETS,
  RELIGIOUS,
  RETAIL_AND_SHOPPING,
  SERVICES,
  SPORTS,
  THEATRE,
  TRAVEL,
  WELLNESS,
}
