import styled, { keyframes } from 'styled-components';

const slideInLeft = keyframes`
  from {
    transform: translateX(720px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const StyledEditCampaign = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: ${slideInLeft} 400ms;

  .edit-campaign {
    &__form {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__options {
      display: flex;
      justify-content: center;
      background: #65b147;

      > .bp3-button {
        margin: 8px 6px;
      }
    }

    &__top-controls {
      padding: 12px;
      background: white;
      border-bottom: 1px solid #d4d6d9;
      flex: 0;
    }

    &__bottom-controls {
      background: white;
      border-top: 1px solid #d4d6d9;
      flex: 0;
      display: flex;
      justify-content: center;

      > .bp3-button {
        margin: 12px 6px;
      }
    }

    &__form-contents {
      flex: 1;
      overflow-y: auto;
    }

    &__main {
      margin: 12px;
    }
  }
`;
