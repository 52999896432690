import * as React from 'react';
import styled from 'styled-components';
import { Button } from '@blueprintjs/core';
import { IconNames } from '@blueprintjs/icons';

import { Colors } from '../../../config';

const Container = styled.div`
  height: 40px;
  width: 100%;
  padding: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: row;
  background-color: ${Colors.BP_BACKGROUND};
  color: black;
  border-bottom: 1px solid lightgray;

  :last-child {
    border-bottom: none;
  }
`;

interface BoundsField {
  label: string;
  onClick: () => void;
}
const BoundsField: React.FunctionComponent<BoundsField> = ({
  label,
  onClick,
}) => (
  <Container>
    <span>{label}</span>
    <Button icon={IconNames.CROSS} onClick={onClick} />
  </Container>
);

export default BoundsField;
