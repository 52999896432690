import * as React from 'react';
import { Line } from 'react-chartjs-2';

import { getCampaignAnalytics } from '../../utilities/firebase';
import { Campaign } from '@humancollective/seedz-shared';
import { Card } from '@blueprintjs/core';

interface CampaignAnalyticsModal {
  campaign: Campaign;
}

const CampaignAnalyticsModal: React.FunctionComponent<
  CampaignAnalyticsModal
> = ({ campaign }) => {
  const [dayChartData, setDayChartData] = React.useState({});
  const [stats, setStats] = React.useState<{
    totalEvents?: number;
    users?: number;
  }>({});

  const updateAnalytics = async () => {
    const analytics = await getCampaignAnalytics({ campaignId: campaign.id });
    const { dailyCalls, totalEvents, users } = analytics;
    setStats({ totalEvents, users });

    const labels = dailyCalls.map(d => d.label);

    setDayChartData({
      labels,
      datasets: [
        {
          label: 'Tokens Unlocked',
          data: dailyCalls.map(d => d.unlockEvents),
          borderColor: '#FFB82B',
          backgroundColor: '#FFB82B00',
        },
        {
          label: 'Tokens Consumed',
          data: dailyCalls.map(d => d.consumeEvents),
          borderColor: '#16D4EE',
          backgroundColor: '#16D4EE00',
        },
        {
          label: 'Tokens Redeemed',
          data: dailyCalls.map(d => d.redeemEvents),
          borderColor: '#FF5C45',
          backgroundColor: '#FF5C4500',
        },
        {
          label: 'Tokens Saved',
          data: dailyCalls.map(d => d.saveEvents),
          borderColor: '#6DB54C',
          backgroundColor: '#6DB54C00',
        },
      ],
    });
  };

  React.useEffect(() => {
    updateAnalytics();
  }, []);

  return (
    <Card>
      {stats.totalEvents && <div>Total Events: {stats.totalEvents}</div>}
      {stats.users && <div>Unique Users: {stats.users}</div>}
      <Line data={dayChartData} />
    </Card>
  );
};

export default CampaignAnalyticsModal;
