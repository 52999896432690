import * as React from 'react';
import {
  Breadcrumbs,
  Button,
  FormGroup,
  IBreadcrumbProps,
  Intent,
  Switch,
} from '@blueprintjs/core';
import { Link, navigate } from 'gatsby';
import { Formik, FormikProps } from 'formik';
import {
  Address,
  Location,
  LocationBuilder,
  Position,
} from '@humancollective/seedz-shared';
import Loading from '@humancollective/human-dashboard/shared/src/components/Loading';

import * as Form from '../../../components/Form';
import { Routes } from '../../../config';
import categories from '../../../config/categories';
import ImageUploader from '../../ImageUploader';
import {
  BusinessesContext,
  IsUserAdminContext,
  MapContext,
  UIContext,
  UserBusinessContext,
  UserLocationsContext,
  ZonesContext,
} from '../../../contexts';
import {
  API,
  DateUtility,
  Firebase,
  Imgix,
  isPositionInZone,
} from '../../../utilities';
import { errorToast, successToast } from '../../../contexts/UI/Toasts';
import LocationAnalyticsModal from '../../Modal/LocationAnalytics';
import getInitialValues from './utils/getInitialValues';
import { StyledEditLocation } from './display';
import ZoneWarningModal from '../../Modal/ZoneWarning';
import { IconNames } from '@blueprintjs/icons';

interface EditLocationFormProps {
  locationId: string;
}

const BREADCRUMBS: IBreadcrumbProps[] = [
  { text: 'Map', onClick: () => navigate('/map') },
  { text: 'Edit Location' },
];

const EditLocationForm: React.FunctionComponent<EditLocationFormProps> = ({
  locationId,
}) => {
  const [initialLoading, setInitialLoading] = React.useState(false);
  const [initialValues, setInitialValues] = React.useState(
    null as Location | null
  );
  const locations = React.useContext(UserLocationsContext);
  const userBusiness = React.useContext(UserBusinessContext);
  const businesses = React.useContext(BusinessesContext);
  const isUserAdmin = React.useContext(IsUserAdminContext);
  const ui = React.useContext(UIContext);
  const map = React.useContext(MapContext);
  const zones = React.useContext(ZonesContext);

  const loadInitialValues = async () => {
    try {
      const nextValues = await getInitialValues({
        locationId,
        locations,
        userBusiness,
      });
      setInitialValues(nextValues);
    } catch (error) {
      navigate(Routes.MAP);
      ui.showToast({
        ...errorToast,
        message: error.message,
      });
    }
  };

  // If the map selected position changes, update the location position
  React.useEffect(() => {
    if (map.selectedPosition && map.selectedItem && initialValues) {
      // The updated values to use for the selected item
      const updatedValues: Location = {
        ...initialValues,
        ...(map.selectedItem.data as Location),
        position: map.selectedPosition,
      };
      // If the location they placed it in is inside a zone
      const zoneFromNewPosition = isPositionInZone({
        zones,
        position: map.selectedPosition,
      });
      if (zoneFromNewPosition) {
        // Show warning modal they're in a zone
        ui.modal.show({
          heading: 'Place In Zone?',
          body: (
            <ZoneWarningModal
              zone={zoneFromNewPosition}
              dismiss={ui.modal.dismiss}
              onCancel={() => {
                // If they're in a zone, and click cancel on the modal action, set the position
                // back to the default position
                updatedValues.position = initialValues.position;
                setInitialValues(updatedValues);
              }}
            />
          ),
        });
      }

      // Will update the map selected item
      setInitialValues(updatedValues);
    }
  }, [map.selectedPosition]);

  React.useEffect(() => {
    // check if this has already been set or is still loading
    if (initialValues || initialLoading || !userBusiness || !locations) return;
    setInitialLoading(true);
    loadInitialValues();
    return () => {
      // clear on cleanup
      map.removeSelectedPosition();
      map.removeSelectedItem();
    };
  }, [userBusiness, locations]);

  // If the values change, update the map selected item
  React.useEffect(() => {
    if (initialValues) {
      map.setSelectedItem({ type: 'location', data: initialValues });
    }
  }, [initialValues]);

  const addLocation = async (values: Location) => {
    const nextLocation = {
      ...values,
      ...(map.selectedPosition && { position: map.selectedPosition }),
    };
    // saves the location to Firestore
    const submitLocation = async () => {
      const request = await API.post({
        url: '/api/addLocation',
        data: {
          location: nextLocation,
        },
        authenticate: true,
      });
      if (request.status === 'error') {
        ui.showToast({
          ...errorToast,
          message: `Error: ${request.message}`,
        });
      } else if (request.status === 'success') {
        // Clear the coordinates
        map.removeSelectedPosition();
        ui.showToast({
          ...successToast,
          message: `Updated location: ${values.name}`,
        });
        // Reload the form with this locations info
        navigate(`/map?type=location&id=${values.id}`);
      }
    };

    // Check if the expiry date changed
    const didLocationExpiryDateChange = Boolean(
      location &&
        !DateUtility.areTimestampsEqual(
          initialValues!.expiryDate,
          nextLocation.expiryDate
        )
    );
    // if the expiryDate changed, show a confirmation modal since they
    // will be charged credits
    if (didLocationExpiryDateChange) {
      const creditCostRequest = await API.post({
        url: '/api/calculateCreditCost',
        data: {
          item: nextLocation,
          itemType: 'location',
        },
      });
      if (creditCostRequest.status === 'success') {
        ui.modal.show({
          heading: 'Confirm',
          body: `You will be charged ${
            creditCostRequest.values.credits
          } credit(s) for extending the expiry date.`,
          actions: [
            {
              text: 'Confirm',
              onClick: async () => {
                ui.modal.dismiss();
                await submitLocation();
              },
              intent: 'primary',
            },
            {
              text: 'Dismiss',
              onClick: () => ui.modal.dismiss(),
              intent: 'none',
            },
          ],
        });
      } else {
        ui.modal.show({
          body: 'There was an error. Please try again later.',
          actions: [
            {
              text: 'Dismiss',
              onClick: () => ui.modal.dismiss(),
              intent: 'none',
            },
          ],
        });
      }
    } else {
      // if the expiry date did not change, simply save the location
      await submitLocation();
    }
  };

  return !initialValues ? (
    <Loading />
  ) : (
    <StyledEditLocation>
      <div className="edit-location__top-controls">
        <Breadcrumbs items={BREADCRUMBS} />
      </div>
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        // validationSchema={validationSchema}
        validate={values => {
          map.setSelectedItem({ type: 'location', data: values });
        }}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          // if the location doesn't exist yet, show a confirmation modal
          const isNewLocation: boolean =
            locationId === 'new' &&
            !Boolean(await Firebase.getLocation(values.id));
          if (isNewLocation) {
            ui.modal.show({
              heading: 'Add Location',
              body: 'Add this location?',
              actions: [
                {
                  text: 'Confirm',
                  intent: 'success',
                  onClick: async () => {
                    ui.modal.dismiss();
                    await addLocation(values);
                  },
                },
              ],
            });
          } else {
            await addLocation(values);
          }
          setSubmitting(false);
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
          errors,
          setTouched,
        }: FormikProps<Location>) => (
          <Form.StyledForm className="edit-location__form">
            <div className="edit-location__form-contents">
              {locationId !== 'new' && (
                <div className="edit-location__options">
                  <Button
                    icon="chart"
                    text="View Analytics"
                    onClick={() =>
                      ui.modal.show({
                        heading: `${values.name} - Analytics`,
                        body: <LocationAnalyticsModal location={values} />,
                        actions: [
                          {
                            text: 'Close',
                            onClick: () => ui.modal.dismiss(),
                            intent: Intent.NONE,
                          },
                        ],
                      })
                    }
                  />
                  <Link
                    to={`/builder?location=${values.id}&screen=contents`}
                    className="bp3-button bp3-icon-build"
                  >
                    Edit Contents
                  </Link>
                </div>
              )}

              <div className="edit-location__images">
                <div
                  className="edit-location__images__cover"
                  style={{
                    backgroundImage:
                      values.data &&
                      values.data.locationPhoto &&
                      `url(${Imgix.createUrl(values.data.locationPhoto, {
                        height: 280,
                        width: 720,
                      })})`,
                  }}
                />
                <div className="edit-location__images__logo">
                  {values.markerImage && (
                    <img
                      src={Imgix.createUrl(values.markerImage, {
                        height: 45,
                        width: 45,
                        mask: 'CORNERS',
                        cornerRadius: 36,
                        fit: 'FILL',
                      })}
                    />
                  )}
                </div>
              </div>

              <div className="edit-location__image-uploaders">
                <ImageUploader
                  label="Upload Logo"
                  onChange={({ imagePath }) =>
                    setFieldValue('markerImage', imagePath)
                  }
                />
                <ImageUploader
                  label="Upload Photo"
                  onChange={({ imagePath }) =>
                    setFieldValue('data.locationPhoto', imagePath)
                  }
                />
              </div>

              <div className="edit-location__main">
                <Form.FieldInput
                  title="Business Name"
                  name="name"
                  placeholder="The name of the location"
                />

                <Form.FieldSelect
                  name="type"
                  title="Business Category"
                  placeholder="Select a Category:"
                  options={Object.keys(categories).map(c => ({
                    value: c,
                    label: categories[c].label,
                  }))}
                />

                {isUserAdmin && (
                  <FormGroup label="Business" labelFor="owner">
                    <div className="bp3-select">
                      <select
                        name="owner"
                        value={values.owner}
                        onChange={handleChange}
                      >
                        <option key="no-category" value="NONE">
                          Select a Business
                        </option>
                        {businesses.map(b =>
                          b.name ? (
                            <option key={b.id} value={b.id}>
                              {b.name}
                            </option>
                          ) : null
                        )}
                      </select>
                    </div>
                  </FormGroup>
                )}

                <Form.FieldDate
                  name="expiryDate"
                  title="Expiry Date"
                  value={values.expiryDate}
                  error={errors.expiryDate}
                  onChange={date => setFieldValue('expiryDate', date)}
                  setTouched={setTouched}
                />

                <Form.FieldAddress
                  title="Address"
                  value={values.address}
                  error={errors.address}
                  onChange={(nextAddress: Address) =>
                    setFieldValue('address', nextAddress)
                  }
                  onChangePosition={(nextPosition: Position) => {
                    setFieldValue('position', nextPosition);
                  }}
                />
                <Form.FieldContact
                  title="Contact Informaiton"
                  value={values.data}
                  error={errors.data}
                  onChange={nextValue => setFieldValue('data', nextValue)}
                />

                <FormGroup
                  labelFor="live"
                  helperText="Users can only see live locations."
                  inline={true}
                >
                  <Switch
                    id="live"
                    name="live"
                    label="Live"
                    defaultChecked={values.live}
                    onChange={e => setFieldValue('live', e.target.checked)}
                  />
                </FormGroup>
                {/*
                  If they have a website added, show the option to
                  add the website as the seed content
                */}
                {values.data && values.data.website && (
                  <FormGroup
                    labelFor="useLinkContent"
                    helperText="Use the locations website as seed content"
                    inline={true}
                  >
                    <Switch
                      id="useLinkContent"
                      name="useLinkContent"
                      label="Website content"
                      defaultChecked={Boolean(
                        values.contents && values.contents.url
                      )}
                      onChange={e => {
                        const { checked } = e.target;
                        // Check if we have a url field in the contents already
                        const hasUrlInContents: boolean = Boolean(
                          values.contents && values.contents.url
                        );
                        // If the switch is checked & we don't have a url in the contents, add it
                        if (checked && !hasUrlInContents) {
                          setFieldValue('contents.url', values.data!.website);
                        } else if (!checked && hasUrlInContents) {
                          // If the switch is unchecked & we have a url in the contents, delete it
                          const currentContent = {
                            ...(values.contents as LocationBuilder),
                          };
                          delete currentContent.url;
                          setFieldValue('contents', currentContent);
                        }
                      }}
                    />
                  </FormGroup>
                )}
              </div>
            </div>
            <div className="edit-location__bottom-controls">
              <Button
                icon="floppy-disk"
                text="Save Location"
                disabled={isSubmitting}
                intent={Intent.SUCCESS}
                onClick={() => handleSubmit()}
              />
              {locationId !== 'new' && (
                <Button
                  icon={IconNames.DELETE}
                  text="Remove"
                  disabled={isSubmitting}
                  onClick={() => {
                    ui.modal.show({
                      heading: 'Delete Location?',
                      body: 'Are you sure you want to delete this location?',
                      actions: [
                        {
                          text: 'Confirm',
                          intent: 'success',
                          onClick: async () => {
                            await Firebase.removeLocation(locationId);
                            ui.modal.dismiss();
                            navigate(Routes.MAP);
                          },
                        },
                        {
                          text: 'Cancel',
                          intent: 'none',
                          onClick: () => {
                            ui.modal.dismiss();
                          },
                        },
                      ],
                    });
                  }}
                />
              )}
            </div>
          </Form.StyledForm>
        )}
      </Formik>
    </StyledEditLocation>
  );
};

export default EditLocationForm;
