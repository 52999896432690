import React from 'react';
import styled, { keyframes } from 'styled-components';
import { geolocated, GeolocatedProps } from 'react-geolocated';
import Helmet from 'react-helmet';
import { WindowLocation } from '@reach/router';

import ProfileOverlay from '../components/ProfileOverlay';
import MapPanel from '../components/MapPanel';
import Map from '../components/Map';
import AuthenticatedContent from '../components/AuthenticatedContent';

import {
  BusinessesContextProvider,
  MapContextProvider,
  ProfileContextProvider,
  SeedzContextProvider,
  UserCampaignsContextProvider,
  UserLocationsContextProvider,
  ZonesContextProvider,
} from '../contexts';
import { getURLParams } from '../utilities';

const fadeIn = keyframes`
  from { opacity: 0; }
  to { opacity: 1; }
`;

const StyledMapPage = styled.div`
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;

  .page-map__map-contents {
    width: 100%;
    background: #f6f8fa;
    flex-grow: 1;
    display: flex;
    position: relative;
  }

  .locations-page__pane {
    min-width: 360px;
    display: flex;
    flex-direction: column;
  }

  .locations-page__locations {
    overflow-y: auto;
  }

  .card {
    margin: 12px;
    animation: ${fadeIn} 1s ease;
  }

  .btn-toolbar {
    margin: 12px;
  }

  .page-map__map-container {
    flex: 1;
    position: relative;
  }
`;

interface MapProps extends GeolocatedProps {
  searchParams: {
    type?: string;
    id?: string;
  };
}

const ConnectedMapPage = geolocated({
  positionOptions: { enableHighAccuracy: false },
  userDecisionTimeout: 5000,
})((props: MapProps) => {
  // Set the default centre to somewhere downtown?
  const [defaultCentre, setDefaultCentre] = React.useState<{
    lat: number;
    lng: number;
  }>({ lat: 43.6425662, lng: -79.3870568 });

  // if we get new coords of the users location, set the default centre
  React.useEffect(() => {
    if (props.coords) {
      setDefaultCentre({
        lat: props.coords.latitude,
        lng: props.coords.longitude,
      });
    }
  }, [props.coords]);

  return (
    <AuthenticatedContent>
      <StyledMapPage>
        <Helmet>
          <title>Map - Seedz</title>
        </Helmet>
        <ProfileOverlay />
        <div className="page-map__map-contents">
          <MapPanel searchParams={props.searchParams} />
          <div className="page-map__map-container">
            <Map
              searchParams={props.searchParams}
              className="map-page__seed-map"
              {...props.searchParams}
              defaultCenter={defaultCentre}
            />
          </div>
        </div>
      </StyledMapPage>
    </AuthenticatedContent>
  );
});

const MapPage: React.FunctionComponent<{ location: WindowLocation }> = ({
  location,
}) => (
  <ZonesContextProvider>
    <SeedzContextProvider>
      <UserCampaignsContextProvider>
        <UserLocationsContextProvider>
          <BusinessesContextProvider>
            <ProfileContextProvider>
              <MapContextProvider searchParams={getURLParams(location.search)}>
                <ConnectedMapPage
                  searchParams={getURLParams(location.search)}
                />
              </MapContextProvider>
            </ProfileContextProvider>
          </BusinessesContextProvider>
        </UserLocationsContextProvider>
      </UserCampaignsContextProvider>
    </SeedzContextProvider>
  </ZonesContextProvider>
);

export default MapPage;
