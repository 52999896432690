import * as React from 'react';
import styled from 'styled-components';
import { Zone } from '@humancollective/seedz-shared';
import geolib from 'geolib';
import { Button } from '@blueprintjs/core';
import { BusinessesContextProvider, BusinessesContext } from '../../contexts';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin: 0;

  > img {
    height: 150px;
    width: 150px;
    border-radius: 75px;
    align-self: center;
  }
`;

const Actions = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  button {
    margin-right: 20px;

    &:last-child {
      margin-right: 0;
    }
  }
`;

const Option = styled.div`
  padding-top: 5px;
  > p {
    margin: 0;
  }

  > h4 {
    margin-bottom: 5px;
    margin: 0;
  }
`;

const NoOptionsTitle = styled.span`
  width: 100%;
  text-align: center;
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
`;

const createStaticZoneMap = (zone: Zone) => {
  // The base URL
  let defaultURL =
    'https://maps.googleapis.com/maps/api/staticmap?zoom=13&maptype=roadmap&size=200x200&scale=4';
  // Adding the centre coordinate
  const centrePosition = geolib.getCenter(zone.bounds);
  defaultURL += `&center=${centrePosition.latitude},${
    centrePosition.longitude
  }`;
  // The Bounds
  const allCoords = zone.bounds
    .map(position => `${position.latitude},${position.longitude}`)
    .join('|');
  defaultURL += `&path=color:0x00000000|weight:5|fillcolor:0xa5ca8c|${allCoords}`;
  // Adding the API key
  const urlWithAPIkey = `${defaultURL}&key=${process.env.GATSBY_MAP_API_KEY}`;
  // Encode the URL
  return encodeURI(urlWithAPIkey);
};

interface ZoneWarningModal {
  zone: Zone;
  onCancel?: () => void;
  onConfirm?: () => void;
  dismiss: () => void;
}

const ZoneWarningModalRoot: React.FunctionComponent<ZoneWarningModal> = ({
  zone,
  onCancel,
  onConfirm,
  dismiss,
}) => {
  const businesses = React.useContext(BusinessesContext);
  return (
    <Container>
      <img src={createStaticZoneMap(zone)} alt="" />
      {/* The options for the zone */}
      {zone.options.disableMarkers && (
        <Option>
          <h4>Placement Of New Markers</h4>
          <p>Not Allowed</p>
        </Option>
      )}
      {zone.options.creditMultiplier && (
        <Option>
          <h4>Credit Cost Multiplier</h4>
          <p>{zone.options.creditMultiplier}x increase</p>
        </Option>
      )}
      {zone.options.enabledBusinesses && (
        <Option>
          <h4>Allowed Businesses</h4>
          {businesses
            .filter(business =>
              zone.options.enabledBusinesses!.includes(business.id)
            )
            .map(business => (
              <p key={business.id}>{business.name}</p>
            ))}
        </Option>
      )}
      {/* If there's no options set */}
      {!Boolean(Object.keys(zone.options).length) && (
        <NoOptionsTitle>There are no rules for this zone.</NoOptionsTitle>
      )}
      <Actions>
        <Button
          text="Confirm"
          intent="success"
          onClick={() => {
            if (onConfirm) onConfirm();
            dismiss();
          }}
        />
        <Button
          text="Cancel"
          intent="danger"
          onClick={() => {
            if (onCancel) onCancel();
            dismiss();
          }}
        />
      </Actions>
    </Container>
  );
};

const ZoneWarningModal: React.FunctionComponent<ZoneWarningModal> = ({
  ...rest
}) => (
  <BusinessesContextProvider>
    <ZoneWarningModalRoot {...rest} />
  </BusinessesContextProvider>
);

export default ZoneWarningModal;
