import styled, { keyframes } from 'styled-components';

export const slideInLeft = keyframes`
  from {
    transform: translateX(720px);
    opacity: 0;
  }
  to {
    transform: translateX(0);
    opacity: 1;
  }
`;

export const StyledEditLocation = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  animation: ${slideInLeft} 400ms;

  .edit-location {
    &__form {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__top-controls {
      padding: 12px;
      background: white;
      border-bottom: 1px solid #d4d6d9;
      flex: 0;
    }

    &__bottom-controls {
      background: white;
      border-top: 1px solid #d4d6d9;
      flex: 0;
      display: flex;
      justify-content: center;

      > .bp3-button {
        margin: 12px 6px;
      }
    }

    &__options {
      display: flex;
      justify-content: center;
      background: #65b147;

      > .bp3-button {
        margin: 8px 6px;
      }
    }

    &__image-uploaders {
      display: flex;
      justify-content: center;

      > .bp3-button {
        margin: 12px 6px;
      }
    }

    &__form-contents {
      flex: 1;
      overflow-y: auto;
    }

    &__main {
      margin: 12px;
    }

    &__images {
      position: relative;

      &__cover {
        background: #64b144;
        height: 140px;
        background-size: cover;
        background-position: center;
      }

      &__logo {
        margin-left: -42px;
        left: 50%;
        position: absolute;
        top: 50%;
        height: 84px;
        width: 84px;
        margin-top: -42px;

        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
`;

export const MarkerImageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  img {
    height: 100px;
    width: 100px;
    border-radius: 50px;
    border: none;
    background-color: white;
    margin-right: 10px;
  }
`;
