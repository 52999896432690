import React from 'react';
import { Formik, FormikProps } from 'formik';
import * as yup from 'yup';

import * as Form from '../Form';
import { Seed } from '@humancollective/seedz-shared';
import { API } from '../../utilities';
import { UIContext } from '../../contexts';

const validationSchema = yup.object().shape({
  expiryDate: yup.object().required(),
});

interface AddSeedModalProps {
  seed: Seed;
}

const AddSeedModal: React.FunctionComponent<AddSeedModalProps> = ({ seed }) => {
  const ui = React.useContext(UIContext);
  return (
    <Formik
      initialValues={seed}
      validationSchema={validationSchema}
      onSubmit={async (values, { setSubmitting }) => {
        setSubmitting(true);
        await API.post({
          url: '/api/addSeed',
          data: { seed: values },
          authenticate: true,
        });
        ui.modal.dismiss();
        setSubmitting(false);
      }}
    >
      {({
        isSubmitting,
        handleSubmit,
        values,
        setFieldValue,
        errors,
        setTouched,
        isValid,
      }: FormikProps<Seed>) => (
        <Form.StyledForm
          onSubmit={e => {
            e.preventDefault();
            handleSubmit();
          }}
        >
          <p>
            In order to create a seed you must set an expiry date for it. When
            the Seed expires it will dissapear from the map. Currently, each day
            the seed is active costs 1 credit.
          </p>
          <Form.FieldDate
            name="expiryDate"
            title="Expiry Date"
            value={values.expiryDate}
            error={errors.expiryDate}
            onChange={date => setFieldValue('expiryDate', date)}
            setTouched={setTouched}
          />
          {values.expiryDate &&
            values.expiryDate.toDate().getTime() > new Date().getTime() && (
              <p>
                This will cost{' '}
                {Math.ceil(
                  (values.expiryDate.toDate().getTime() -
                    new Date().getTime()) /
                    (24 * 60 * 60 * 1000)
                )}{' '}
                credits
              </p>
            )}
          <Form.SubmitButton
            label="Confirm"
            disabled={isSubmitting || !isValid}
          />
        </Form.StyledForm>
      )}
    </Formik>
  );
};

export default AddSeedModal;
