import {
  Button,
  FormGroup,
  Switch,
  IBreadcrumbProps,
  InputGroup,
  NumericInput,
  Collapse,
} from '@blueprintjs/core';
import { Zone, createId, Business } from '@humancollective/seedz-shared';
import { Formik, FormikProps } from 'formik';
import * as React from 'react';
import { navigate } from 'gatsby';
import { IconNames } from '@blueprintjs/icons';

import { zoneValidation } from '../validation';
import {
  ZonesContext,
  MapContext,
  BusinessesContext,
  UIContext,
  IsUserAdminContext,
} from '../../../contexts';

import { FormError } from '../Fields';
import { BusinessList, BusinessListItem } from './BusinessList';
import * as Form from '../../Form';
import BoundsField from './BoundsField';
import { Firebase } from '../../../utilities';
import { Routes } from '../../../config';

const BREADCRUMBS: IBreadcrumbProps[] = [
  { text: 'Map', onClick: () => navigate('/map') },
  { text: 'Manage Zone' },
];

const initialValues: Zone = {
  id: '', // We set this later
  live: true,
  bounds: [],
  options: {},
};

interface ZoneFormProps {
  zoneId: string;
  onSubmitting?: (values: any) => void | Promise<void>;
}

export const ZoneForm: React.FunctionComponent<ZoneFormProps> = ({
  zoneId,
}) => {
  const zones = React.useContext(ZonesContext);
  const map = React.useContext(MapContext);
  const businesses = React.useContext(BusinessesContext);
  const ui = React.useContext(UIContext);
  const isAdmin = React.useContext(IsUserAdminContext);
  // Holds the zone we're editing
  const [zone, setZone] = React.useState<Zone | undefined>();
  // The search value for allowed businesses
  const [businessSearchValue, setBusinessSearchValue] = React.useState<string>(
    ''
  );
  // The business that are allowed to place markers in the zone
  const [zoneBusinesses, setZoneBusinesses] = React.useState<Business[]>([]);

  // Sets the zone to edit
  React.useEffect(() => {
    // If the zone exists, set it
    // If it does not, set the initial values with a new id
    const existingZone = zones.find(tempZone => tempZone.id === zoneId);
    if (existingZone) {
      setZone({ ...initialValues, ...existingZone });
    } else {
      // Set the zone to the initial values
      setZone({ ...initialValues, id: createId() });
    }
  }, [zoneId, zones]);

  React.useEffect(() => {
    return () => {
      map.removeSelectedItem();
      map.removeSelectedPosition();
    };
  }, []);

  // if the selected position changes (they're selecting the bounds on the map)
  React.useEffect(() => {
    if (map.selectedPosition && zone) {
      const updatedZone: Zone = {
        ...zone,
        bounds: [...zone.bounds, map.selectedPosition],
      };
      setZone(updatedZone);
    }
  }, [map.selectedPosition]);

  // if the zone we're editing changes
  React.useEffect(() => {
    if (zone) {
      // Update the zone in the map context so the changes reflect on the map
      map.setSelectedItem({ type: 'zone', data: zone });

      // Update the allowed businesses
      if (zone.options.enabledBusinesses) {
        // Go through the valid businesses and get the corresponding business from the context
        const validBusinesses = zone.options.enabledBusinesses
          .map(validBusinessId =>
            businesses.find(business => business.id === validBusinessId)
          )
          .filter(allowedBusiness => allowedBusiness) as Business[];
        setZoneBusinesses(validBusinesses);
      }
    }
  }, [zone]);

  React.useEffect(() => {
    if (zone && !isAdmin) {
      navigate(Routes.MAP);
      ui.showToast({
        message: 'You do not have permission to edit this zone',
        intent: 'warning',
        icon: 'error',
      });
    }
  }, [zone, isAdmin]);

  const manageBusinessZone = React.useCallback(
    ({ businessId, remove }: { businessId: string; remove: boolean }) => {
      // Adds the business id to valid businesses for the zones
      if (zone) {
        // If we're removing the business id
        if (remove && zone.options.enabledBusinesses) {
          const filteredZoneBusinesses = zone.options.enabledBusinesses.filter(
            allowedBusinessId => allowedBusinessId !== businessId
          );
          zone.options.enabledBusinesses = filteredZoneBusinesses;
        }

        // If we're adding a new business Id
        if (!remove) {
          // if we already have businesses
          if (zone.options.enabledBusinesses) {
            zone.options.enabledBusinesses.push(businessId);
          } else {
            // if we don't already have businesses, create a new array
            zone.options.enabledBusinesses = [businessId];
          }
        }

        // Update the zone
        setZone({ ...zone });

        // Remove the search query
        setBusinessSearchValue('');
      }
    },
    [zone]
  );

  return zone ? (
    <Formik
      initialValues={zone}
      onSubmit={async (values: Zone, { setSubmitting }) => {
        setSubmitting(true);
        try {
          // Delete the options field(s) if they have no values
          if (!values.options.disableMarkers) {
            delete values.options.disableMarkers;
          }
          if (
            !values.options.creditMultiplier ||
            values.options.creditMultiplier === 0
          ) {
            delete values.options.creditMultiplier;
          }
          if (
            values.options.enabledBusinesses &&
            values.options.enabledBusinesses.length === 0
          ) {
            delete values.options.enabledBusinesses;
          }
          // Add the zone
          await Firebase.zone.set(values);
          ui.showToast({
            message: 'Succesfully saved zone',
            intent: 'success',
          });
          // Set the URL to the current zone
          navigate(`${Routes.MAP}?type=zone&id=${zone.id}`);
        } catch (error) {
          ui.showToast({ message: 'Error saving zone', intent: 'danger' });
        }
        setSubmitting(false);
      }}
      enableReinitialize={true}
      validationSchema={zoneValidation}
    >
      {({
        isSubmitting,
        values,
        handleSubmit,
        setFieldValue,
        errors,
        handleChange,
      }: FormikProps<Zone>) => (
        <Form.FormRoot onSubmit={handleSubmit} breadcrumbs={BREADCRUMBS}>
          {/* The zone bounds */}
          <FormGroup labelFor="bounds" label="Bounds">
            {errors.bounds && <FormError label={errors.bounds as string} />}
            {values.bounds.length === 0 ? (
              <span>Right click on the map to set the zone bounds</span>
            ) : (
              values.bounds.map((bounds, index) => (
                <BoundsField
                  key={`${bounds.latitude}, ${bounds.longitude}`}
                  label={`${index + 1}. ${Number(bounds.latitude).toFixed(
                    6
                  )}, ${Number(bounds.longitude).toFixed(6)}`}
                  onClick={() => {
                    // Remove the bounds
                    zone.bounds.splice(index, 1);
                    setZone({
                      ...zone,
                    });
                  }}
                />
              ))
            )}
          </FormGroup>
          <FormGroup
            labelFor="live"
            helperText="Users can only see live zones."
            inline={true}
          >
            <Switch
              id="live"
              name="live"
              label="Live"
              defaultChecked={values.live}
              onChange={e => setFieldValue('live', e.target.checked)}
            />
          </FormGroup>
          {/* The zone options */}
          <FormGroup
            labelFor="options.disableMarkers"
            helperText="Allow new seedz or locations inside the zone"
            inline={true}
          >
            <Switch
              id="markersDisabled"
              name="options.markersDisabled"
              label="Disable Markers"
              defaultChecked={Boolean(values.options.disableMarkers)}
              onChange={e =>
                setFieldValue('options.disableMarkers', e.target.checked)
              }
            />
          </FormGroup>
          <FormGroup
            labelFor="options.creditMultiplier"
            label="Credit Multiplier"
            helperText="Increases credit cost for seedz & locations in the zone"
          >
            {errors.options && errors.options.creditMultiplier && (
              <FormError label={errors.options.creditMultiplier} />
            )}
            <NumericInput
              name="options.multiplier"
              value={values.options.creditMultiplier || 0}
              min={0}
              max={0.9}
              stepSize={0.1}
              onValueChange={value =>
                setFieldValue('options.creditMultiplier', value)
              }
            />
          </FormGroup>
          {/* Valid businesses */}
          <FormGroup
            labelFor="options.validBusinesses"
            label="Allowed Businesses"
          >
            {/* Searching for the business */}
            <InputGroup
              leftIcon={IconNames.SEARCH}
              onChange={event => setBusinessSearchValue(event.target.value)}
              value={businessSearchValue}
            />
            {/* Search results */}
            <Collapse isOpen={Boolean(businessSearchValue)}>
              {/* Show all the businesses whos name includes the search value */}
              <BusinessList>
                {businesses
                  .filter(business =>
                    business.name.includes(businessSearchValue)
                  )
                  .map(business => (
                    <BusinessListItem
                      key={business.id}
                      {...business}
                      onClick={() => {
                        manageBusinessZone({
                          businessId: business.id,
                          remove: false,
                        });
                      }}
                      isSearching={true}
                    />
                  ))}
              </BusinessList>
            </Collapse>
            {/* The zones valid businesses */}
            {!Boolean(businessSearchValue) && (
              <BusinessList>
                {zoneBusinesses.map(allowedBusiness => (
                  <BusinessListItem
                    key={allowedBusiness.id}
                    {...allowedBusiness}
                    onClick={() => {
                      manageBusinessZone({
                        businessId: allowedBusiness.id,
                        remove: true,
                      });
                    }}
                  />
                ))}
              </BusinessList>
            )}
          </FormGroup>
          <Button
            type="submit"
            icon="floppy-disk"
            text="Save"
            disabled={isSubmitting}
          />
          <Button
            type="button"
            icon="trash"
            text="Delete"
            onClick={() => {
              ui.modal.show({
                heading: 'Delete',
                body: 'Are you sure you want to delete this zone?',
                actions: [
                  {
                    text: 'Confirm',
                    intent: 'success',
                    onClick: async () => {
                      await Firebase.zone.remove(zone.id);
                      navigate(Routes.MAP);
                      ui.modal.dismiss();
                    },
                  },
                  {
                    text: 'Cancel',
                    intent: 'none',
                    onClick: () => {
                      ui.modal.dismiss();
                    },
                  },
                ],
              });
            }}
          />
        </Form.FormRoot>
      )}
    </Formik>
  ) : null;
};

export default ZoneForm;
