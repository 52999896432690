import * as React from 'react';
import { Formik, FormikProps } from 'formik';
import {
  Breadcrumbs,
  Button,
  FormGroup,
  IBreadcrumbProps,
  InputGroup,
  Intent,
  Switch,
} from '@blueprintjs/core';
import { Link, navigate } from 'gatsby';
import {
  Campaign,
  CampaignCategory,
  createId,
} from '@humancollective/seedz-shared';
import 'react-datetime/css/react-datetime.css';

import CampaignAnalyticsModal from '../../Modal/CampaignAnalytics';
import * as Form from '../../../components/Form';
import categories from '../../../config/categories';
import {
  BusinessesContext,
  IsUserAdminContext,
  UIContext,
  UserCampaignsContext,
} from '../../../contexts';
import { errorToast, successToast } from '../../../contexts/UI/Toasts';
import { API, Firebase } from '../../../utilities';
import { StyledEditCampaign } from './display';
import { IconNames } from '@blueprintjs/icons';
import { Routes } from '../../../config';

type EditCampaignFormValues = Campaign;

interface EditCampaignFormProps {
  campaignId: string;
}

const defaultValues: Campaign = {
  type: CampaignCategory.Services,
  id: '',
  markerImage: '',
  name: '',
  business: '',
  unlockUrl: '',
  quantity: 0,
};

const BREADCRUMBS: IBreadcrumbProps[] = [
  { text: 'Map', onClick: () => navigate('/map') },
  { text: 'Edit Campaign' },
];

const EditCampaignForm: React.FunctionComponent<EditCampaignFormProps> = ({
  campaignId,
}) => {
  const isUserAdmin = React.useContext(IsUserAdminContext);
  const campaigns = React.useContext(UserCampaignsContext);
  const businesses = React.useContext(BusinessesContext);
  const ui = React.useContext(UIContext);

  const [campaign, setCampaign] = React.useState<Campaign | undefined>();

  // If we have a campaign with the id 'new', creating a new campaign from the defaultValues
  // If not, find the campaign in the context
  React.useEffect(() => {
    const existingCampaign = campaigns.find(c => c.id === campaignId);
    if (existingCampaign) {
      setCampaign(existingCampaign);
    } else {
      setCampaign({ ...defaultValues, id: createId() });
    }
  }, [campaigns, campaignId]);

  return campaign ? (
    <StyledEditCampaign>
      <div className="edit-campaign__top-controls">
        <Breadcrumbs items={BREADCRUMBS} />
      </div>
      <Formik
        initialValues={{
          ...(campaign as Campaign),
        }}
        enableReinitialize={true}
        onSubmit={async (values, { setSubmitting }) => {
          setSubmitting(true);
          const request = await API.post({
            url: '/api/addCampaign',
            data: {
              campaign: values as Campaign,
            },
            authenticate: true,
          });
          setSubmitting(false);
          if (request.status === 'error') {
            ui.showToast({
              ...errorToast,
              message: `Error: ${request.message}`,
            });
          } else if (request.status === 'success') {
            ui.showToast({
              ...successToast,
              message: `Updated Campaign: ${values.name}`,
            });
            navigate(`${Routes.MAP}?type=campaign&id=${campaign.id}`);
          }
        }}
      >
        {({
          isSubmitting,
          values,
          handleChange,
          handleSubmit,
          setFieldValue,
        }: FormikProps<EditCampaignFormValues>) => (
          <Form.StyledForm className="edit-campaign__form">
            <div className="edit-campaign__form-contents">
              <div className="edit-campaign__options">
                <Button
                  icon="chart"
                  text="View Analytics"
                  onClick={() =>
                    ui.modal.show({
                      heading: `${values.name} - Analytics`,
                      body: <CampaignAnalyticsModal campaign={values} />,
                      actions: [
                        {
                          text: 'Close',
                          onClick: () => ui.modal.dismiss(),
                          intent: Intent.NONE,
                        },
                      ],
                    })
                  }
                />
              </div>
              <div>
                <div className="edit-campaign__main">
                  <Form.FieldInput
                    title="Campaign Name"
                    name="name"
                    placeholder="The name of the campaign"
                  />

                  {campaignId !== 'new' && (
                    <Link
                      to={`/builder?campaign=${campaign.id}&screen=unlocked`}
                      className="bp3-button bp3-icon-build"
                    >
                      Edit Contents
                    </Link>
                  )}

                  {values.isRedeemable && (
                    <Link
                      to={`/builder?campaign=${campaign.id}&screen=redeemed`}
                      className="bp3-button bp3-icon-build"
                    >
                      Edit Redeemed Contents
                    </Link>
                  )}

                  <Form.FieldSelect
                    name="type"
                    title="Campaign Category"
                    placeholder="Select a Category:"
                    options={Object.keys(categories).map(c => ({
                      value: c,
                      label: categories[c].label,
                    }))}
                  />

                  {isUserAdmin && (
                    <FormGroup label="Business" labelFor="business">
                      <div className="bp3-select">
                        <select
                          name="business"
                          value={values.business}
                          onChange={handleChange}
                        >
                          <option key="no-category" value="NONE">
                            Select a Business
                          </option>
                          {businesses.map(b =>
                            b.name ? (
                              <option key={b.id} value={b.id}>
                                {b.name}
                              </option>
                            ) : null
                          )}
                        </select>
                      </div>
                    </FormGroup>
                  )}

                  <FormGroup
                    label="Quantity"
                    labelFor="quantity"
                    helperText={`How many coupons will be available? (used so far:
                      ${values.used || 0})`}
                  >
                    <InputGroup
                      id="quantity"
                      name="quantity"
                      type="number"
                      value={values.quantity || 0}
                      onChange={handleChange}
                    />
                  </FormGroup>

                  <FormGroup
                    labelFor="isRedeemable"
                    helperText="Is this a coupon that can be redeemed?"
                    inline={true}
                  >
                    <Switch
                      id="isRedeemable"
                      name="isRedeemable"
                      label="Redeemable"
                      defaultChecked={values.isRedeemable}
                      onChange={e =>
                        setFieldValue('isRedeemable', e.target.checked)
                      }
                    />
                  </FormGroup>

                  <FormGroup
                    labelFor="live"
                    helperText="Users can only see live campaigns."
                    inline={true}
                  >
                    <Switch
                      id="live"
                      name="live"
                      label="Live"
                      defaultChecked={values.live}
                      onChange={e => setFieldValue('live', e.target.checked)}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>
            <div className="edit-campaign__bottom-controls">
              <Button
                icon="floppy-disk"
                text="Save Campaign"
                disabled={isSubmitting}
                onClick={() => handleSubmit()}
                intent={Intent.SUCCESS}
              />
              {campaignId !== 'new' && (
                <Button
                  icon={IconNames.DELETE}
                  text="Delete"
                  onClick={() => {
                    ui.modal.show({
                      heading: 'Delete Campaign?',
                      body: 'Are you sure you want to delete this campaign?',
                      actions: [
                        {
                          text: 'Confirm',
                          intent: 'success',
                          onClick: async () => {
                            await Firebase.removeCampaign(campaign.id);
                            ui.modal.dismiss();
                            navigate(Routes.MAP);
                          },
                        },
                        {
                          text: 'Cancel',
                          intent: 'none',
                          onClick: () => {
                            ui.modal.dismiss();
                          },
                        },
                      ],
                    });
                  }}
                />
              )}
            </div>
          </Form.StyledForm>
        )}
      </Formik>
    </StyledEditCampaign>
  ) : null;
};

export default EditCampaignForm;
