import * as React from 'react';
import { Line } from 'react-chartjs-2';

import { getLocationAnalytics } from '../../utilities/firebase';
import { Location } from '@humancollective/seedz-shared';
import { Card, Button } from '@blueprintjs/core';

interface LocationAnalyticsModal {
  location: Location;
}

const LocationAnalyticsModal: React.FunctionComponent<
  LocationAnalyticsModal
> = ({ location }) => {
  const [dayChartData, setDayChartData] = React.useState({});
  const [stats, setStats] = React.useState<{
    total?: number;
    uniques?: number;
  }>({});

  const updateAnalytics = async () => {
    const analytics = await getLocationAnalytics({ locationId: location.id });
    const { dailyCalls, total, uniques } = analytics;
    setStats({ total, uniques });

    const labels = dailyCalls.map(d => d.label);
    const values = dailyCalls.map(d => d.value);

    setDayChartData({
      labels,
      datasets: [
        {
          label: 'Page Views',
          data: values,
          borderColor: '#6db54c',
          backgroundColor: '#6db54c66',
        },
      ],
    });
  };

  React.useEffect(() => {
    updateAnalytics();
  }, []);

  return (
    <Card>
      {stats.total && <div>Total Views: {stats.total}</div>}
      {stats.uniques && <div>Unique Views: {stats.uniques}</div>}
      <Line data={dayChartData} />
    </Card>
  );
};

export default LocationAnalyticsModal;
