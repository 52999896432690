import { Location, Seed } from '@humancollective/seedz-shared';
import React from 'react';
import { Marker, MarkerProps } from 'react-google-maps';
import { Imgix } from '../../utilities';

interface MapMarkerProps extends MarkerProps {
  imageUrl: string;
}
const MapMarker: React.FunctionComponent<MapMarkerProps> = ({
  position,
  noRedraw = true,
  onClick,
  imageUrl,
  ...rest
}) =>
  position ? (
    <Marker
      position={position}
      icon={{
        url: Imgix.createUrl(imageUrl, {
          height: 36,
          width: 36,
          cornerRadius: 18,
          mask: 'CORNERS',
          fit: 'CLAMP',
        }),
        scaledSize: new google.maps.Size(36, 36),
      }}
      noRedraw={noRedraw}
      onClick={onClick}
      {...rest}
    />
  ) : null;

export default MapMarker;
