import {
  Business,
  Location,
  LocationCategory,
  Position,
} from '@humancollective/seedz-shared';

import { createId, DateUtility } from '../../../../utilities';
import { DEFAULT_MARKER_IMAGE_PATH } from '../../../../config';
import geocode from '../../../../utilities/geocode';

export interface LoadInitialValuesArgs {
  locationId: string;
  locations: Location[] | null;
  userBusiness: Business | null;
}

export default async ({
  locationId,
  locations,
  userBusiness,
}: LoadInitialValuesArgs) => {
  if (!userBusiness || !locations) throw new Error('Something went wrong');

  // If we're pointing to an existing location we'll simply do a lookup on it
  if (locationId !== 'new') {
    const locationMatch = locations.find(l => l.id === locationId);
    if (!locationMatch) {
      throw new Error('Error: could not find the specified location');
    }
    return locationMatch;
  }

  // If we're pointing to a new location, we'll use the business details as
  // defaults
  const defaultLocation: Location = {
    id: createId(),
    name: '',
    type: LocationCategory.Services,
    markerImage: DEFAULT_MARKER_IMAGE_PATH,
    live: false,
    owner: '',
    expiryDate: DateUtility.now(),
    position: {
      latitude: 0,
      longitude: 0,
    },
  };

  const businessLocation = {
    name: userBusiness.name,
    address: userBusiness.address,
    markerImage: userBusiness.logo,
    data: {
      locationPhoto: '',
      website: userBusiness.metadata && userBusiness.metadata.website,
      phone: userBusiness.metadata && userBusiness.metadata.phone,
      facebookUrl: userBusiness.metadata && userBusiness.metadata.facebookUrl,
      instagramUrl: userBusiness.metadata && userBusiness.metadata.instagramUrl,
      twitterUrl: userBusiness.metadata && userBusiness.metadata.twitterUrl,
      email: userBusiness.metadata && userBusiness.metadata.email,
    },
  };

  const businessAddressString =
    userBusiness.address && Object.values(userBusiness.address).join(', ');
  const position: Position = (await geocode(businessAddressString)) || {
    latitude: 0,
    longitude: 0,
  };

  return { ...defaultLocation, ...businessLocation, position };
};
